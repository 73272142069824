<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col style="text-align: center" cols="10" sm="8" md="6" lg="4">

            <!-- TODO: link to view account -->
            <!-- TODO: show featured products; allow organization to select which products are on front page, or set it up with special banners with a page builder, or something like that; and/or we can show links to view account, go to brand's homepage (link from their brandprofile), etc. we don't need to check if uesr is authenticated, because if they try to access a feature that is for authenticated users only they will get redirected to login page; -->
            <!-- TODO: the list of products needs to be styled better; if there's a product image then show the image and product name in a little card maybe -->
            <!-- TODO: show the cancel button differently, and make it clear that it empties the entire cart ... and not just goes back to last page... maybe there need to be separate buttons for these two things, properly labeled -->
            <template v-if="isViewReady && cart">
                <CheckoutProgress step="payment" :shipping="cart.require.require_shipping" :billing="cart.require.require_billing"/>
            </template>
            <!-- <p class="text-caption text-end">
            </p> -->
            <v-card v-if="isViewReady && cart" class="mt-6">
                <v-app-bar :color="primaryColor" dark flat dense>
                    <v-app-bar-title>Checkout</v-app-bar-title>
                    <v-spacer/>
                    <v-btn text @click="cancel">Cancel</v-btn>
                </v-app-bar>

                <!-- summary of number of items in cart and total amount to be charged today, to give context to the payment information form -->
                <v-card-text>
                <p v-if="isAtLeastOneItemInCart && total.amount_csu > 0">
                    Payment required for {{ itemCountDisplay }}.
                </p>
                <p v-if="isAtLeastOneItemInCart && total.amount_csu === 0">
                    No payment is due for {{ itemCountDisplay }}.
                </p>
                <p>
                    Amount to be charged today: {{ total.currency }} {{ formatAmount(total.currency, total.amount_csu) }}
                    <!-- TODO: if multiple currencies involved, show one for each? or require carts to have items with only one currency per transaction? -->
                </p>
                </v-card-text>

                <v-form @submit.prevent="pay" @keyup.enter.prevent="pay" class="my-4 mx-4">
                    <div id="payment-element">
                        <!-- Stripe.js injects the Payment Element -->
                    </div>
                </v-form>
                <!-- <v-card-text>
                </v-card-text> -->
                <v-card-actions>
                    <v-spacer/>
                    <v-btn :style="primaryButtonStyle" @click="pay" v-if="isAtLeastOneItemInCart && total.amount_csu > 0" :disabled="isPayRequestPending">
                        <span class="request-pending-indicator mr-2" :style="{ 'border-top-color': primaryColor }" v-if="isPayRequestPending"></span>
                        <span>Pay now</span>
                    </v-btn>
                    <v-btn :style="primaryButtonStyle" @click="free" v-if="isAtLeastOneItemInCart && total.amount_csu === 0" :disabled="isFreeRequestPending">
                        <span class="request-pending-indicator mr-2" :style="{ 'border-top-color': primaryColor }" v-if="isFreeRequestPending"></span>
                        Get it free
                    </v-btn>
                </v-card-actions>
                <!-- TODO: subtotal, shipping/taxes, total -->
                <!-- <v-form @submit.prevent="checkout" @keyup.enter.prevent="checkout">
                    <v-simple-table>
                        <template #default>
                            < ! - - <thead>
                                <tr>
                                    <th style="text-align: start; width: 70%;">Where should we email the receipt?</th>
                                    <th style="text-align: end;"></th>
                                </tr>
                            </thead> - - >
                            <tbody>
                                <tr>
                                    <td style="text-align: start; vertical-align: top;" class="pt-6">
                                        <v-text-field label="Enter your email address" placeholder="" outlined dense persistent-placeholder v-model="email" ref="emailInput" :color="primaryColor" :error-messages="emailError">
                                            <template v-slot:prepend>
                                                <font-awesome-icon icon="envelope" fixed-width/>
                                            </template>
                                        </v-text-field>
                                    </td>
                                    <td style="text-align: end; vertical-align: top;" class="pt-6">
                                        <v-btn :style="primaryButtonStyle" @click="checkout">Continue</v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-form> -->
            </v-card>
            <!-- TODO:  if there's an error loading the cart, show temporary error: -->
            <v-card elevation="2" v-if="isViewReady && !cart">
                <v-app-bar color="red darken-2" dark flat dense>
                    <v-app-bar-title>Maintenance</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>The server is temporarily unavailable.</p>
                    <!-- TODO: We are going to automatically retry until it's ready. Please wait.. -->
                    <!-- <p>Return to the last page and contact the emergency home for details.
                    <v-btn :to="loginRoute" elevation="2" :style="primaryButtonStyle" class="my-6" outlined>Sign in</v-btn>
                    <p>No account yet? <a :href="mainWebsiteURL">Sign up</a></p> -->
                    <!-- <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p> -->
                </v-card-text>
            </v-card>
            <!-- <template v-if="!isAuthenticatedReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isAuthenticatedReady">
                <template v-if="isAuthenticated">
                    <v-row style="height: 100%" align="center" justify="center">
                        <div class="app-splash-loader"></div>
                        <p class="mt-6">
                        <router-link :to="{ name: 'dashboard' }">Continue to dashboard</router-link>
                        </p>
                    </v-row>
                </template>
                <template v-if="!isAuthenticated">
                    <LoginCard/>
                </template>
            </template> -->
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
.v-input .v-input__prepend-outer {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}

/* this is expected to show on a disabled button so the button will appear grey; also the location where its used is expected replace the white portion with the brand's primary color */
.request-pending-indicator {
    border: 2px solid #999999; /* grey lighten-4 */
    border-top: 2px solid #ffffff; /* grey lighten-1 */
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1.0s linear infinite;
    /*
    margin: auto;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    */
}
/* NOTE: the spin is defined in index.html */
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import { toMillis } from '@libertyio/time-util-js';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
import { loadStripe } from '@stripe/stripe-js';
// import CheckoutItemQuantity from '@/components/CheckoutItemQuantity.vue';
// import CheckoutItemCustomAmount from '@/components/CheckoutItemCustomAmount.vue';
import CheckoutProgress from '@/components/CheckoutProgress.vue';
// import { isValidEmail } from '@/sdk/input';

let stripeAccount = null;
let stripe = null;
let stripeElements = null;
let stripePaymentElement = null;

export default {
    components: {
        CheckoutProgress,
        // CheckoutItemQuantity,
        // CheckoutItemCustomAmount,
    },
    data: () => ({
        product: null,
        isViewReady: false,
        // email: null,
        // emailError: null,
        // emailErrorTimeout: null,
        checkoutTimestamp: null,
        orderId: null,
        orderStatus: null,
        stripePaymentIntent: null,
        paymentAmountCSU: null,
        paymentAmountNumber: null,
        paymentAmountText: null,
        paymentCurrency: null,
        // mode: 'prompt', // 'prompt' shows line items and continue button, 'email' prompts for email
        isPayRequestPending: false, // prevents duplicate processing
        isFreeRequestPending: false, // prevents duplicate processing
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
            focus: (state) => state.focus,
            cart: (state) => state.cart,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        total() {
            if (Array.isArray(this.cart?.totals)) {
                return this.cart.totals[0];
            }
            return {}; // { currency: 'USD', amount_csu: 0 }
        },
        itemCountDisplay() {
            if (this.cart?.items?.length === 1) {
                return '1 item';
            }
            if (this.cart?.items?.length !== 1) {
                return `${this.cart.items.length} items`;
            }
            return 'unknown items';
        },
        isAtLeastOneItemInCart() {
            return this.cart?.items?.length > 0;
        },
        frontLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-front', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'front' };
            }
            return link;
        },
        searchLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'search', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'search' };
            }
            return link;
        },
        checkoutAccountLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-account', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-account' };
            }
            return link;
        },
        isPaymentRequired() {
            return this.paymentCurrency && Number.isFinite(this.paymentAmountCSU) && this.paymentAmountCSU > 0;
        },
        // isCheckoutFormComplete() {
        //     return typeof this.email === 'string' && this.email.length > 0 && isValidEmail(this.email);
        // },
        stripekey() {
            return process.env.VUE_APP_STRIPE_KEY;
        },
    },
    watch: {
        isAuthenticatedReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.init();
            }
        },
        // isAuthenticated(newValue, oldValue) {
        //     if (newValue && !oldValue) {
        //         this.redirectAuthenticatedUser();
        //     }
        // },
        brandprofile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.init();
            }
        },
        focus() {
            if (this.brandprofile) {
                this.init();
            }
        },
        cart(newValue) {
            let total = null;
            if (newValue?.totals?.length > 0) {
                total = newValue.totals[0];
            }
            if (total?.currency && Number.isInteger(total?.amount_csu)) {
                this.paymentCurrency = total.currency;
                this.paymentAmountCSU = total.amount_csu;
                const paymentAmount = fromCurrencyAmountCSU(total.currency, total.amount_csu);
                this.paymentAmountNumber = paymentAmount.toNumber();
                this.paymentAmountText = paymentAmount.toString();
            } else {
                this.paymentCurrency = null;
                this.paymentAmountCSU = null;
                this.paymentAmountNumber = null;
                this.paymentAmountText = null;
            }
        },
        isPaymentRequired(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.preparePaymentForm();
            }
        },
    },
    methods: {
        // {
        //     path: '/receipt',
        //     name: 'main-receipt',
        //     meta: { layout: 'brand-layout', isPublic: true },
        //     component: () => import(/* webpackChunkName: "receipt" */ '../views/Receipt.vue'),
        // },
        // {
        //     path: '/brand/:brandprofile/receipt',
        //     name: 'brand-receipt',
        //     meta: { layout: 'brand-layout', isPublic: true },
        //     component: () => import(/* webpackChunkName: "receipt" */ '../views/Receipt.vue'),
        // },
        // NOTE: stripe will automatically add query parameters 'payment_intent', 'payment_intent_client_secret', and 'redirect_status=succeeded' to the link when payment is done
        // TODO: take stripe payment intent id as parameter and check on that specific one
        statusLink() {
            const origin = process.env.VUE_APP_CUSTOMER_WEBSITE_URL;
            let link;
            if (this.brandselector === 'brandprofile') {
                link = `${origin}/brand/${encodeURIComponent(this.brandprofile)}/cart/payment-status`;
            } else {
                link = `${origin}/cart/payment-status`;
            }
            return link;
        },
        receiptLink(orderId) {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-receipt', params: { brandprofile: this.brandprofile }, query: { order_id: orderId } };
            } else {
                link = { name: 'main-receipt', query: { order_id: orderId } };
            }
            return link;
        },
        // redirectAuthenticatedUser() {
        //     // TODO: we need to check query parameters, if there's an organization id redirect to the dashboard for that organization, otherwise if there's only one organization, go to that one, or if there's more than one show the organization selection , and if the user doens't have any organizations then show a message that they need to contact the administrator to be added to an organization
        //     this.$router.replace({ name: 'dashboard' });
        // },
        async init() {
            try {
                this.$store.commit('loading', { loadCartForPayment: true });
                await this.$store.dispatch('loadCart');
            } catch (err) {
                console.error('loadCartForPayment failed', err);
            } finally {
                this.$store.commit('loading', { loadCartForPayment: false });
                this.isViewReady = true;
            }
        },
        async preparePaymentForm() {
            try {
                if (Number.isInteger(this.checkoutTimestamp) && this.checkoutTimestamp + 500 > Date.now()) {
                    return;
                }
                this.checkoutTimestamp = Date.now();
                // if (!this.isCheckoutFormComplete) {
                //     if (this.email) {
                //         this.emailError = 'Please enter a valid email address';
                //     } else {
                //         this.emailError = 'Please enter your email address';
                //     }
                //     this.$activateInput('emailInput');
                //     if (this.emailErrorTimeout) {
                //         clearTimeout(this.emailErrorTimeout);
                //     }
                //     this.emailErrorTimeout = setTimeout(() => { this.emailError = null; }, toMillis({ seconds: 10 }));
                //     return;
                // }
                // this.emailError = null;
                this.$store.commit('loading', { checkout: true });

                if (!this.stripekey) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Service is temporarily unavailable', message: 'Configuration error' });
                    return;
                }

                const response = await this.$client.site(this.brandprofile).cart.payment(/* { email: this.email } */);
                if (response.order_id) {
                    this.orderId = response.order_id;
                }
                if (response.order_status) {
                    this.orderStatus = response.order_status;
                }
                if (response.stripe_payment_intent) {
                    this.stripePaymentIntent = response.stripe_payment_intent;
                }

                if (response.order_status === 'paid') {
                    console.log('order is already paid');
                    this.$router.replace(this.receiptLink(this.orderId));
                    return;
                }

                if (response.order_status === 'pending_payment') {
                    // NOTE: 'stripeAccount' is a global defined outside 'this' Vue instance
                    if (stripe === null || stripeAccount !== response.stripe_account) {
                        // TODO: also need to check if the stripe account changed
                        stripe = await loadStripe(this.stripekey, { stripeAccount: response.stripe_account });
                        stripeAccount = response.stripe_account;
                    }

                    /*
                    // to redirect to stripe's website for checkout:
                    if (response?.redirect) {
                        window.location.href = response.redirect;
                    } else {
                        // TODO: ??? display error message?
                    }
                    */

                    // to use stripe elements here:
                    if (response?.stripe_client_secret) {
                        // NOTE: 'stripeElements' is a global defined outside 'this' Vue instance
                        if (stripeElements === null) {
                            // TODO: if brandprofile changed during checkout (it shouldn't! ) we need to show an error message and take the user back to cart to start over
                            stripeElements = stripe.elements({
                                clientSecret: response.stripe_client_secret,
                                appearance: {
                                    theme: 'stripe', // https://stripe.com/docs/stripe-js/appearance-api#theme
                                    variables: { // https://stripe.com/docs/stripe-js/appearance-api#variables
                                        colorPrimary: this.primaryColor,
                                        colorBackground: '#ffffff',
                                        colorText: '#333333',
                                        colorDanger: '#D32F2F', // red darken-2 from https://vuetifyjs.com/en/styles/colors/#material-colors
                                    },
                                },
                                // rules: {}, // https://stripe.com/docs/stripe-js/appearance-api#rules
                                // https://stripe.com/docs/stripe-js/appearance-api#others
                            });
                        }
                        // console.log(stripeElements);
                        // https://stripe.com/docs/js/element/events
                        // TODO: set up events for change, ready, , etc. ??

                        // TODO: currently server only supports 'card' payments ... latgernatively create 'payment'
                        // stripeCardElement = stripeElements.create('card'); // https://stripe.com/docs/js/elements_object/create_element?type=card
                        // stripeCardElement.mount('#card-element');
                        // NOTE: 'stripePaymentElement' is a global defined outside 'this' Vue instance
                        if (stripePaymentElement === null) {
                            stripePaymentElement = stripeElements.create('payment'); // https://stripe.com/docs/js/elements_object/create_payment_element
                            stripePaymentElement.mount('#payment-element');
                        }
                    } else {
                        console.warn('stripe client secret not present');
                    }
                }
            } catch (err) {
                console.error('checkout failed', err);
            } finally {
                this.$store.commit('loading', { checkout: false });
                this.isViewReady = true;
            }
        },
        async cancel() {
            await this.clearCart();
            this.$nav.push(this.searchLink);
        },
        formatAmount(currency, amount) {
            // return fromCurrencyAmountCSU(this.price.currency, amount).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(currency, amount ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', { // TODO: localization, if we know user's locale use that instead of en-US
                currency,
                style: 'currency',
            }).format(price);
            return display;
        },
        async pay() {
            if (stripe === null || stripeElements === null) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Service is temporarily unavailable', message: 'Try reloading the page' });
                return;
            }
            this.isPayRequestPending = true;
            // TODO: implement a timeout so if we don't get a response from stripe we re-enable the button to let the user try again; this is also where would implement idempotency, so if user tries again but stripe already processed the request, using the same idepotency key will prevent a duplicate charge

            // payment could fail due to invalid credit card or other reasons
            try {
                const paymentResponse = await stripe.confirmPayment({
                    elements: stripeElements,
                    confirmParams: {
                        return_url: this.statusLink(this.stripePaymentIntent),
                    },
                });
                if (paymentResponse?.error) {
                    console.error(`payment failed with error: ${JSON.stringify(paymentResponse.error)}`);
                    if (paymentResponse.error.type === 'card_error' && paymentResponse.error.message) {
                        this.$bus.$emit('snackbar', {
                            type: 'error',
                            headline: 'Card error',
                            message: paymentResponse.error.message,
                            timeout: 10000,
                        });
                    } else {
                        this.$bus.$emit('snackbar', {
                            type: 'error',
                            headline: 'Payment failed',
                            message: 'Please check the payment form and try again',
                            timeout: 10000,
                        });
                    }
                }
            } catch (err) {
                if (err) {
                    console.error('payment failed', err);
                }
            } finally {
                this.isPayRequestPending = false;
            }
        },
        async free() {
            // cannot skip payment if payment is required!
            if (this.isPaymentRequired) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Order must be paid first' });
                return;
            }
            this.isFreeRequestPending = true;
            // if we haven't already, call the payment API to mark this free order as 'paid'
            console.log(`Payment.vue: free: orderId ${this.orderId} order status ${this.orderStatus}`);
            if (!this.orderId || !this.orderStatus === 'paid') {
                const response = await this.$client.site(this.brandprofile).cart.payment(/* { email: this.email } */);
                if (response.order_id) {
                    this.orderId = response.order_id;
                }
                if (response.order_status) {
                    this.orderStatus = response.order_status;
                }
            }
            // then show the free order receipt
            if (this.orderId && ['paid', 'activated', 'processed'].includes(this.orderStatus)) {
                this.$router.replace(this.receiptLink(this.orderId));
                return;
            }
            console.log(`payment.vue: order id ${this.orderId} order status ${this.orderStatus}`);
            this.$bus.$emit('snackbar', { type: 'error', headline: 'There was a problem with the order' });
            this.isFreeRequestPending = false;
        },
    },
    mounted() {
        // if (this.hostname === window.location.hostname) {
        //     // special case for front page of main site, it's not a storefront
        // }
        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
        if (this.brandprofile) {
            this.init();
        }
    },
};
</script>
